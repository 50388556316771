.f-245 {
    font-size: 24px;
    font-weight: 500;
}

.f-134 {
    font-size: 13px;
    font-weight: 400;
}

.f-135 {
    font-size: 13px;
    font-weight: 500;
}

.f-145 {
    font-size: 14px;
    font-weight: 500;
}

.f-165 {
    font-size: 16px;
    font-weight: 500;
}
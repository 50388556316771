.ridewidth {
  /* width: 33.33%; */
  /* padding: 20px; */
  margin: 10px;
  text-align: left;
  align-items: center;
}

.city-heading {
  font-size: 14px;
}

.heading ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  /* background-color: rgb(123, 128, 123); */
  background-color: rgb(92 93 92);
}

.heading li {
  float: left;
}

.heading li a {
  display: block;
  color: rgb(255 255 255);
  text-align: center;
  padding-left: 5px;
  text-decoration: none;
  font-weight: bold;
}

.topLocation {
  color: rgb(38, 159, 251);
  font-weight: 500;
  font-size: 18px !important;
}

.carSpinner {
  min-height: 200px;
  display: flex !important;
  align-items: center;
}

/* .sub-heading:hover {
  color: black;
  cursor: pointer;
} */

.sub-heading {
  color: white;
  font-weight: 800;
  padding-left: 5px;
}

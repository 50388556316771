.reportModal-cancelBtn {
  margin-right: 20px;
  padding: 0.7rem 1rem;
  background: linear-gradient(180deg, #fff, #f9fafb);
  border: 1px solid #c4cdd5;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border-radius: 3px;
  line-height: 1;
  color: #212b36;
}

.reportModal-exportBtn {
  margin-right: 20px;
  border-radius: 3px;
  line-height: 1;
  padding: 0.7rem 1rem;
  background: linear-gradient(180deg, #6371c7, #5563c1);
  border-color: #3f4eae;
  box-shadow: inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgba(22, 29, 37, 0.05),
    0 0 0 0 transparent;
  color: #fff;
  fill: #fff;
}


.MuiInputBase-root{
  cursor: pointer !important;
}
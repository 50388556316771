@import "../../static/scss/newVariable.scss";

.f13 {
    font-size: 13px;
}

.storeId-dropdown {
    border-radius: 3px;
    border: 1px solid #ccc;
    height: 28px;
}

.storeId-dropdown:focus {
    outline: none;
}
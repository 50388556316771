/*--------------------------------------------------dashboard-menu-----------------------------------------------------*/
.dashboard-menu {
  position: fixed;
  right: 16px;
  width: 100px;
  height: 52px;
  background-color: none;
  z-index: 1000;
}

.render-dashboard-menu {
  background-color: whitesmoke;
  color: black;
  font-size: 12px;
  font-weight: 500;

  transition: box-shadow 100ms linear;
}

.render-dashboard-menu:hover {
  box-shadow: 0px 0px 1px 2px #0000002b;
}

.dashboard-menu-active {
  background-color: rgb(56, 121, 186,);
  color: white;
}

.dashboard-menu-active > div > div {
  color: white;
}

a:hover {
  text-decoration: none !important;
  color: black !important;
}

.MuiListItemIcon-root {
  min-width: 30px !important;
}
@import "./base.scss";

$color_1: $white;
$color_2: #61dafb;
$background_color_1: #282c34;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-header {
  background-color: $background_color_1;
  height: 265px;
  // display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $color_1;
}
.App-link {
  color: $color_2;
}
a {
  color: $white;
}
.dash-board {
  height: 100%;
  width: 100vw;
}
.side-panel-body {
  width: calc(100% - 3.5rem);
  float: right;
  padding: 1rem 1.5rem 0rem 1.5rem !important;
}

.primary-shadow {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px #8181ec !important;
}

.primary-cards {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 4px 0px -5px #8181ec !important;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
}
